import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { AgGridReact } from "ag-grid-react";
import FileDownloader, { FileDownloaderType } from "../../components/file-downloader/FileDownloader";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { ColDef } from "ag-grid-community";
import IconsCellRenderer, {
  IconsCellRendererProps,
} from "../../components/ag-grid-extensions/renderers/IconsCellRenderer";
import { faDownload } from "@elynx/pro-regular-svg-icons";
import { useAccountingInvoiceList } from "../../queries/accounting-invoices/UseAccountingInvoiceList";
import {
  AccountingBlobType,
  AccountingInvoiceDescription,
  defaultAccountingInvoiceQueryProperties,
} from "../../queries/accounting-invoices/Models";
import { useGetBlobUrl } from "../../queries/accounting-invoices/UseGetBlobUrl";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";

export default function NonPolledData() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { displayToast } = useToastNotification();
  const fileDownloaderRef = useRef<FileDownloaderType>(null);
  const [invoiceQueryProperties, setInvoiceQueryProperties] = useState(defaultAccountingInvoiceQueryProperties);
  const [invoiceList, setinvoiceList] = useState<Array<AccountingInvoiceDescription>>([]);
  const {
    data: newinvoiceList,
    //isFetching: invoiceListFetching,
    isSuccess: invoiceListSuccess,
    //refetch: refetchinvoiceList,
  } = useAccountingInvoiceList({
    enabled: true,
    accountingInvoiceQueryProperties: invoiceQueryProperties,
  });
  const { mutate: getBlobUrl } = useGetBlobUrl();

  //store the new data when it arrives
  useEffect(() => {
    if (newinvoiceList) {
      //do a little cleanup on the data before we save it
      const cleanedList = newinvoiceList.map((l) => {
        return {
          ...l,
          invoiceNumber: l.invoiceNumber?.substring(l.invoiceNumber.indexOf(" - ") + 2) ?? "",
        } as AccountingInvoiceDescription;
      });
      setinvoiceList(cleanedList);
    }
  }, [invoiceListSuccess, newinvoiceList]);

  const handleDownloadFileClick = useCallback((item: AccountingInvoiceDescription) => {
    if (item) {
      getBlobUrl(item, {
        onSuccess: (data) => fileDownloaderRef.current?.download(data, data),
        onError: (error) =>
          displayToast({
            message: `Error getting blob Url for ${item.blobName}: ${error}`,
            severity: "error",
            duration: 15000,
          }),
      });
    }
  }, []);

  const handleDateChange = (date: Date | null) => {
    //clear the existing invoice list to avoid confusion
    setinvoiceList([]);

    //set the new date into the query properties
    if (date) {
      setInvoiceQueryProperties({ ...invoiceQueryProperties, timestamp: date });
    }
  };

  const handleTypeChange = (blobType: AccountingBlobType) => {
    //clear the existing invoice list to avoid confusion
    setinvoiceList([]);

    //set the new blob type into the query properties
    setInvoiceQueryProperties({ ...invoiceQueryProperties, blobType: blobType });
  };

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Actions",
        colId: "actions",
        cellRenderer: IconsCellRenderer,
        cellRendererParams: {
          iconDescriptions: [
            {
              trueIcon: faDownload,
              fontSize: "medium",
              onClick: handleDownloadFileClick,
              trueTooltipText: "Download file",
              shouldDisplay: true,
            },
          ],
        } as IconsCellRendererProps,
        width: 100,
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: "MAS Account ID",
        field: "masAccountID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 200,
      },
      {
        headerName: "Sales Order",
        field: "salesOrderID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 170,
      },
      {
        headerName: "Invoice Number",
        field: "invoiceNumber",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 200,
      },
      {
        headerName: "Account Name",
        colId: "masAccountName",
        valueGetter: (params) => {
          return params.data.masAccountName.replace(/\.[^/.]+$/, "");
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 320,
      },
      {
        headerName: "Invoice Name",
        colId: "invoiceName",
        valueGetter: (params) => {
          return AccountingBlobType.Invoice === invoiceQueryProperties.blobType ||
            AccountingBlobType.InvoicePdf === invoiceQueryProperties.blobType
            ? `${params.data.masAccountID}_${params.data.salesOrderID}_${params.data.masAccountName}`
            : AccountingBlobType.InvoiceImportSummary === invoiceQueryProperties.blobType
            ? `${params.data.blobName.split("/")[2]}`
            : "";
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 420,
      },
    ];
  }, [invoiceQueryProperties.blobType]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"Accounting Invoices"} />
        <Stack
          direction="row"
          spacing={1}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <DatePicker
            views={["year", "month"]}
            label="Year and Month"
            value={invoiceQueryProperties.timestamp}
            onChange={(newValue: Date | null) => {
              handleDateChange(newValue);
            }}
            slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Invoice Type"
            value={invoiceQueryProperties.blobType}
            onChange={(e) => handleTypeChange(+e.target.value)}
            sx={{ width: "25ch" }}
          >
            <MenuItem value={1}>Invoice (XLSX)</MenuItem>
            <MenuItem value={2}>Invoice (PDF)</MenuItem>
            <MenuItem value={3}>Invoice Import Summary</MenuItem>
          </TextField>
        </Stack>

        {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={invoiceList}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              suppressClickEdit={true}
              suppressCellFocus={true}
              alwaysMultiSort={true}
              columnMenu="legacy"
            />
          </div>
        </Box>
      </ContentView>
      <FileDownloader ref={fileDownloaderRef} />
    </>
  );
}
